const SPONSOR_LANDING = 'sponsor_landing';
const SEARCH = 'search';
const PLATFORM_IOS = 'ios';
const PLATFORM_ANDROID = 'android';
const AUTO_REFRESH_INITIAL = 'initial';

const DEFAULT_PAGE: string[] = [SPONSOR_LANDING, SEARCH];

const DEFAULT_PLATFORM: string[] = [PLATFORM_IOS, PLATFORM_ANDROID];

export const setDefaultAutoRefresh = (banner: Element, params: URLSearchParams) => {
  const page = params.get('page') || '';
  const platform = params.get('platform') || '';

  if (DEFAULT_PAGE.includes(page) && DEFAULT_PLATFORM.includes(platform)) {
    banner.setAttribute('data-auto_refresh', AUTO_REFRESH_INITIAL);
  }
};
